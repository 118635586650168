







import Vue from 'vue';
import {Backend} from "api-frontend";
import {getResourceUrl} from "@/services/getResource";

export default Vue.extend({
  name: 'CarolSinging',
  props: {
    backend: Backend
  },
  methods: {
    getVideoUrl() {
      return getResourceUrl("/carolSinging/carol-singing-in-the-cloud.mp4", this.backend)
    },
    downloadVideo() {

    },
  }
})
